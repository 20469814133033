<template>
  <b-modal
    ref="myModal"
    v-model="modalcenter"
    centered
    modal
    size="lg"
    header-class="p-0"
    no-close-on-backdrop
    header-bg-variant="transparent border-bottom border-bottom-2"
    modal-class="modal-primary"
    title="TO ASSIGN"
    title-class="h2 text-white"
    @hidden="hideModal"
    hide-footer
    scrollable
  >
    <template v-slot:modal-header>
      <ModalHeader
        :dataItem="dataItem"
        :title="'ASSIGN SUB MODULE AND ROLE'"
        @close="hideModal()"
      />
    </template>
    <validation-observer ref="form">
      <b-row style="margin-left: 3px">
        <b-col cols="4">
          <b-form-group label="Select Sub Module">
            <validation-provider
              v-slot="{ errors }"
              name="module"
              rules="required"
              :key="keymodule1"
            >
              <b-select
                v-model="select_module"
                value-field="id"
                text-field="name"
                :options="optionsModules"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
                @input="selectModule"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Select Role">
            <validation-provider
              v-slot="{ errors }"
              name="role"
              rules="required"
              :key="keymodule2"
            >
              <b-select
                v-model="select_role"
                value-field="id"
                text-field="name"
                :options="optionsRoles"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
                @input="selectRole"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button
            style="width: 40%; margin-top: 27px !important"
            variant="primary"
            @click="save()"
            >Add</b-button
          >
        </b-col>
      </b-row>
      <b-row style="margin-left: 3px" v-if="flagHiddenLanguage">
        <b-col cols="4">
          <b-form-group label="Select Skill">
            <validation-provider
              v-slot="{ errors }"
              name="language"
              rules="required"
              :key="keymodule3"
            >
              <b-select
                v-model="select_language"
                :options="optionsLanguages"
                value-field="id"
                text-field="name"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
              >
              </b-select>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4" v-if="select_module == 20 && select_role == 15">
          <b-form-group label="Select Level">
            <validation-provider
              v-slot="{ errors }"
              name="level"
              rules="required"
              :key="keymodule3"
            >
              <b-select
                v-model="select_level"
                :options="optionsAgentLevel"
                value-field="id"
                text-field="name"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
              >
              </b-select>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row style="margin-left: 3px" v-if="flagHiddenSeller">
        <b-col cols="4">
          <b-form-group label="Select Level">
            <validation-provider
              v-slot="{ errors }"
              name="typesenior"
              rules="required"
              :key="keymodule4"
            >
              <b-select
                v-model="select_typesenior"
                :options="optionsTypesenior"
                value-field="id"
                text-field="name"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
              >
              </b-select>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-container>
      <b-table
        slot="table"
        ref="refsTable"
        class="position-relative font-small-3"
        primary-key="id"
        empty-text="No matching records found"
        select-mode="multi"
        responsive="sm"
        table-class="text-nowrap"
        sticky-header="68vh"
        small
        show-empty
        :fields="visibleFields"
        :items="myProvider"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(role)="data">
          <div style="margin-top: 3px">
            <b-badge variant="primary"
              >{{ data.item.role }}
              {{
                data.item.role == "Seller"
                  ? data.item.status === "PENDING"
                    ? " - " + data.item.typesenior_pending + " ( PENDING )"
                    : "( " + data.item.typesenior + " )"
                  : ""
              }}
            </b-badge>
          </div>
        </template>

        <template #cell(languages)="data">
          <div class="text-center">
            <b-badge
              v-if="data.item.role_id == 15"
              pill
              :variant="'light-success'"
              class="text-capitalize w-100"
            >
              {{ data.item.languages }}</b-badge
            >
          </div>
        </template>
        <template #cell(action)="data">
          <div class="text-center">
            <feather-icon
              class="text-info cursor-pointer"
              v-b-tooltip.hover.right="'Delete'"
              icon="Trash2Icon"
              size="20"
              @click="deleteRow(data.item)"
            >
            </feather-icon>
          </div>
        </template>
      </b-table>
    </b-container>
  </b-modal>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
import vSelect from "vue-select";
import UsersService from "../../service/users.service";
import { mapGetters } from "vuex";
export default {
  components: {
    vSelect,
    ModalHeader,
  },
  props: {
    dataItem: {
      type: Object,
    },
  },
  data() {
    return {
      arrModules: [],
      deleteSeller: 0,
      keymodule1: 0,
      keymodule2: 0,
      keymodule3: 0,
      keymodule4: 0,
      modalcenter: false,
      optionsModules: [],
      select_module: null,
      optionsRoles: [],
      select_role: null,
      select_language: null,
      select_typesenior: null,
      select_level: null,
      flagHiddenLanguage: false,
      flagHiddenSeller: false,
      dataTable: [],
      optionsLanguages: [
        {
          id: 1,
          name: "MONOLINGUAL",
        },
        {
          id: 2,
          name: "BILINGUAL",
        },
      ],
      optionsTypesenior: [
        {
          id: 1,
          name: "TRAINER",
        },
        {
          id: 2,
          name: "JUNIOR",
        },
        ,
        {
          id: 3,
          name: "SENIOR",
        },
        ,
        {
          id: 4,
          name: "MASTER",
        },
      ],
      optionsAgentLevel: [
        {
          id: 1,
          name: "TRAINER",
        },
        {
          id: 2,
          name: "JUNIOR",
        },
        {
          id: 3,
          name: "SENIOR",
        },
        {
          id: 4,
          name: "MASTER",
        },
      ],
      fields: [
        {
          key: "module",
          label: "Module",
          visible: true,
        },
        {
          key: "role",
          label: "Role",
          visible: true,
        },

        {
          key: "languages",
          label: "Skill",
          visible: true,
          thClass: "text-center",
        },
        {
          key: "action",
          label: "Action",
          visible: true,
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    flag() {
      let module_id = this.select_module;
      let role_id = this.select_role;
      this.dataTable.forEach(function (item) {
        if (item.module_id == module_id && item.role_id == role_id) {
          return true;
        }
      });
      return false;
    },
  },
  async created() {
    this.modalcenter = true;
    await this.getAllModules();
  },
  methods: {
    async selectModule() {
      try {
        let module_id = this.select_module;
        const params = {
          module_id,
        };
        this.select_role = null;
        this.flagHiddenLanguage = false;
        this.flagHiddenSeller = false;
        this.keymodule2++;
        this.keymodule3++;
        this.keymodule4++;
        this.optionsRoles = await UsersService.getAllRolesForModule(params);
      } catch (error) {
        console.error(error);
      }
    },
    selectRole() {
      if (this.select_role == 15) {
        this.flagHiddenLanguage = true;
        let flag = false;
        let languages = null;
        this.dataTable.forEach(function (item) {
          if (item.role_id == 15) {
            flag = true;
            languages = item.languages == "MONOLINGUAL" ? 1 : 2;
          }
        });
        if (flag) {
          this.select_language = languages;
        }
      } else if (this.select_role == 5) {
        this.flagHiddenSeller = true;
        let flag = false;
        let seller = null;
        this.dataTable.forEach(function (item) {
          if (item.role_id == 5) {
            flag = true;
            seller =
              item.typesenior == 1
                ? " Trainer"
                : item.typesenior == 2
                ? " Junior"
                : item.typesenior === 3
                ? "Senior"
                : item.typesenior === 4
                ? "Master"
                : "";
          }
        });
      } else {
        this.flagHiddenLanguage = false;
        this.flagHiddenSeller = false;
      }
    },
    async deleteRow(data) {
      try {
        data.module_id === 26
          ? (this.deleteSeller = 1)
          : (this.deleteSeller = 0);
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {
          this.addPreloader();
          const params = {
            id: data.id,
            user_session_id: this.currentUser.user_id,
            languages: this.languages(data),
            parent_id: data.parent_id,
            user_id: data.user_id,
            delete_seller: this.deleteSeller,
          };
          const response = await UsersService.deleteRowUserModule(params);
          if (response.status == 200) {
            this.removePreloader();
            this.refresh();
            this.$emit("refresh");
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
    languages(data) {
      if (data.role_id == 15) {
        if (data.languages == "MONOLINGUAL") {
          return 1;
        } else if (data.languages == "BILINGUAL") {
          return 2;
        }
      } else {
        return null;
      }
    },
    async myProvider() {
      try {
        const params = {
          _user_id: this.dataItem.user_id,
        };
        const response = await UsersService.getAllModuleAndRoleForUser(params);
        if (response.status == 200) {
          this.dataTable = response.data;
          return response.data;
        }
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      }
    },
    refresh() {
      this.$refs.refsTable.refresh();
    },
    hideModal() {
      this.modalcenter = false;
      this.$refs['myModal'].hide();
      this.$emit("hideModal");
    },
    async save() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          let module_id = this.select_module;
          let flag = false;
          this.dataTable.forEach(function (item) {
            if (item.module_id == module_id) {
              flag = true;
              return;
            }
          });
          if (flag) {
            this.showWarningSwal("Record already exists");
            return;
          }
          const params = {
            user_id: this.dataItem.user_id,
            module_id: this.select_module,
            role_id: this.select_role,
            user_session_id: this.currentUser.user_id,
            language: this.select_language,
            _typesenior: this.select_typesenior,
          };
          if (this.select_module == 20) {
            params.agent_level = this.select_level;
          }
          this.addPreloader();
          const response = await UsersService.saveModuleAndRole(params);
          if (response.status == 200) {
            this.removePreloader();
            this.refresh();
            this.$emit("refresh");
            this.select_module = null;
            this.select_role = null;
            this.select_language = null;
            this.select_typesenior = null;
            this.keymodule1++;
            this.keymodule2++;
            this.keymodule3++;
            this.keymodule4++;
            this.flagHiddenLanguage = false;
            this.flagHiddenSeller = false;
            this.optionsRoles = [];
          }
        }
      } catch (error) {
        console.error(error);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    async getAllModules() {
      this.optionsModules = await UsersService.getAllModules();
    },
  },
};
</script>
<style scoped></style>

export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    options: [
      { label: "ACTIVE", id: 1 },
      { label: "INACTIVE", id: 0 },
    ],
    model: null,
    reduce: "id",
    selectText: "label",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Modules",
    options: [],
    model: null,
    reduce: "id",
    selectText: "value",
    cols: 12,
    md: 2,
    visible: false,
  },
  {
      type:"select",
      margin:true,
      showLabel:true,
      label:"Roles",
      options:[],
      model:null,
      reduce:"id",
      selectText:"value",
      cols:12,
      md:2,
      visible:false
  }

];

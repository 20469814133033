<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      modal
      size="lg"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="TRACKING ASSIGNED CLIENTS ADVISOR"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <template v-slot:modal-header>
        <ModalHeader
          :dataItem="dataItem"
          :title="'TRACKING ASSIGNED CLIENTS ADVISOR'"
          @close="hideModal()"
        />
      </template>
      <filter-slot
        :filter-principal="filterPrincipal"
        :filter="filter"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refresh"
      >
      <template #table>
          <b-table
          slot="table"
          ref="refsTable"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          class="overflow-y"
          >
            <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
           <template #cell(created_at)="data">
            <div>
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>
          </b-table>
      </template>
      </filter-slot>
    </b-modal>
  </div>
</template>

<script>
import UsersService from "../../service/users.service";
import ModalHeader from "./ModalHeader.vue";
export default {
  components: {
    ModalHeader,
  },
  props: {
    dataItem: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,

      filter: [],
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      sortBy: "created_at",
      sortDesc: true,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,

      fields: [
        {
          key: "user_session",
          label: "Assigned by",
          visible: true,
        },
        {
          key: "user_advisor",
          label: "Assigned to",
          visible: true,
        },
        {
          key: "client_name",
          label: "Client",
          visible: true,
        },
        {
          key: "account",
          label: "Account",
          visible: true,
        },
        {
          key: "created_at",
          label: "Creation Date",
          visible: true,
        },
      ],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    hideModal() {
      this.modalCenter = false;
      this.$refs['myModal'].hide();
      this.$emit("hideModal");
    },
    refresh() {
      this.$refs.refsTable.refresh();
    },
    async myProvider() {
      try {
        const params = {
          user_id: this.dataItem.user_id,
          text:this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,

        };
        this.addPreloader();
        const response = await UsersService.getAllTrackingAsignClientsAdvisor(
          params
        );
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        this.items = response.data.data;
        this.removePreloader();
        return response.data.data;
      } catch (e) {
        console.error(e);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalCenter"
      centered
      modal
      size="lg"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="TRACKING STATUS"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <template v-slot:modal-header>
        <ModalHeader
          :dataItem="dataItem"
          :title="'TRACKING STATUS'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <b-table
          slot="table"
          ref="refTrackingTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="visibleFields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div>
                <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>
           <template #cell(status)="data">
              <b-badge
                pill
                :variant="data.item.status == 1 ? 'success' : 'warning'"
                class="text-capitalize w-100"
                >{{ data.item.status == 1 ? "ACTIVE" : "INACTIVE" }}</b-badge
              >
            </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import UsersService from "../../service/users.service";
import ModalHeader from "./ModalHeader.vue";
export default {
  components: {
    ModalHeader,
  },
  props: {
    dataItem: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "created_at",
          label: "Date",
          visible: true,
        },
        {
          key: "user_session",
          label: "User Session",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          thClass: "text-center",
          visible: true,
        },
      ],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    hideModal() {
      this.$refs['myModal'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        const response = await UsersService.getAllTrackingStatusForUser({
          user_id: this.dataItem.user_id,
        });
        return response.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped></style>

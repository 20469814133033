export default [
  {
    key: "user_name",
    label: "User",
    visible: true,
  },
  {
    key: "email",
    label: "Email",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "modules",
    label: "Module(s)",
    visible: true,
  },
  {
    key: "roles",
    label: "Role(s)",
    visible: true,
  },
  {
    key: "status_session",
    label: "Status session",
    visible: true,
  },
  {
    key: "phone",
    label: "Phone Number",
    visible: true,
  },
  {
    key: "creation_date",
    label: "Creation Date",
    visible: true,
  },
  {
    key: "tracking",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "actions",
    visible: true,
    thClass: "text-center"
  }
];

<template>
  <div>

    <b-card no-body class="mb-0 border-0 p-0">
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refresh()"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refUsersTable1"
            class="position-relative font-small-3"
            primary-key="id"
            empty-text="No matching records found"
            responsive="sm"
            table-class="text-nowrap"
            sticky-header="68vh"
            small
            show-empty
            sort-icon-left
            :busy="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :fields="visibleFields"
            :items="myProvider"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(user_name)="data">
              <div v-if="moduleC == 6">
                {{ data.item.user_name }}
              </div>
              <div v-if="moduleC == 21">
                <div class="d-flex justify-content-start">
                  {{ data.item.user_name }}
                </div>
                <b-badge v-if="data.item.identify_module" variant="primary"
                  >CREDIT EXPERT</b-badge
                >
              </div>
              <div v-if="moduleC == 20">
                {{ data.item.user_name }}
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="data.item.status == 1 ? 'success' : 'warning'"
                class="text-capitalize w-100"
                >{{ data.item.status == 1 ? "ACTIVE" : "INACTIVE" }}</b-badge
              >
            </template>
            <template #cell(actions)="data">
              <div
                v-if="data.item.is_chief == 0"
                class="d-flex justify-content-center"
              >
                <div
                  style="width: 55px"
                  v-if="data.item.cc_clients > 0 && data.item.status == 0"
                />
                <div v-if="data.item.status == 1">
                  <b-button
                    variant="outline"
                    @click="openModal(data.item)"
                    title="Assign module and role"
                  >
                    <feather-icon class="text-primary" icon="PlusIcon" />
                  </b-button>
                  <b-button
                    variant="outline"
                    @click="openModalEditLevel(data.item)"
                    title="Edit agent role and level"
                    v-if="
                      $route.meta.module_c == 20 &&
                      data.item.modules_roles != null &&
                      hasConnectionAgentRole(data.item)
                    "
                  >
                    <feather-icon class="text-warning" icon="EditIcon" />
                  </b-button>
                </div>
                <div style="width: 55px" v-if="data.item.status == 0"></div>
                <div class="d-flex justify-content-center">
                  <b-button
                    variant="outline"
                    @click="activate(data.item)"
                    v-if="data.item.status == 0"
                    title="Activate user"
                  >
                    <feather-icon class="text-primary" icon="ThumbsUpIcon" />
                  </b-button>
                  <b-button
                    variant="outline"
                    @click="inactivate(data.item)"
                    v-if="data.item.status == 1"
                    title="Inactivate user"
                  >
                    <feather-icon class="text-danger" icon="ThumbsDownIcon" />
                  </b-button>
                  <b-button
                    v-if="data.item.cc_clients > 0 && data.item.status == 0"
                    variant="outline"
                    @click="openModalAssignClients(data.item)"
                    title="Reassign this advisor's clients"
                  >
                    <tabler-icon
                      class="text-info"
                      icon="SwitchHorizontalIcon"
                    />
                  </b-button>
                </div>
              </div>
            </template>
            <template #cell(creation_date)="data">
              <div>
                <span>{{ data.item.creator_name }}</span>
                <br />
                <span>{{ data.item.created_at | myGlobal }}</span>
              </div>
            </template>
            <template v-slot:cell(status_session)="data">
              <div
                v-for="(item, index) in data.item.modules_roles"
                :key="index"
                style="margin-bottom: 5px"
              >
                <span v-if="item.user_status == 1">
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #00d25b; color: #00d25b"
                  />
                  Active
                </span>
                <span v-if="item.user_status === 2">
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #ff9f43; color: #ff9f43"
                  />
                  Busy
                </span>
                <span v-if="item.user_status === 3">
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #fc424a; color: #fc424a"
                  />
                  Away
                </span>
                <span v-if="item.user_status === 4 || item.user_status === 0">
                  <feather-icon
                    icon="CircleIcon"
                    size="17"
                    style="fill: #82868b; color: #82868b"
                  />
                  Offline
                </span>
              </div>
            </template>
            <template #cell(tracking)="data">
              <div class="text-center">
                <b-button
                  v-if="moduleC === 21 || moduleC === 20"
                  variant="outline"
                  title="Tracking role"
                  @click="openModalTracking(data.item)"
                >
                  <feather-icon class="text-info" icon="ListIcon">
                  </feather-icon>
                </b-button>
                <b-button
                  variant="outline"
                  title="Tracking status"
                  @click="openModalTrackingStatus(data.item)"
                >
                  <feather-icon class="text-info" icon="ListIcon">
                  </feather-icon>
                </b-button>
                <b-button
                  v-if="
                    data.item.identify_module && moduleC === 21
                  "
                  variant="outline"
                  title="Tracking clients assigned advisor"
                  @click="openModalTrackingAsiggnClientsAdvisor(data.item)"
                >
                  <feather-icon class="text-info" icon="ListIcon">
                  </feather-icon>
                </b-button>
              </div>
            </template>
            <template #cell(modules)="data">
              <div
                v-for="(item, index) in data.item.modules_roles"
                :key="index"
                style="margin-top: 3px"
              >
                <b-badge variant="info">{{ item.module_name }}</b-badge>
              </div>
            </template>
            <template #cell(roles)="data">
              <div
                v-for="(item, index) in data.item.modules_roles"
                :key="index"
                style="margin-top: 3px"
              >
                <b-badge variant="primary"
                  >{{ item.role_name }}
                  {{ formatRoleDetails(item) }}
                  {{
                    item.role_name == "Seller"
                      ? (item._status ==="PENDING" ? ' - '+ item._typesenior_pending + ' ( PENDING )': "( " + item._typesenior + " )" ) 
                      : '' 
                      
                  }}
                  </b-badge
                >
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
    <modal-to-assign
      :dataItem="dataItem"
      v-if="modaltoAssignState"
      @hideModal="closeModal"
      @refresh="refresh"
    />
    <ModalClientsAssigned
      v-if="modalClientsAssignedState"
      :dataItem="dataItem"
      @hideModal="modalClientsAssignedState = false"
      @refresh="refresh"
    ></ModalClientsAssigned>
    <modal-tracking
      v-if="modalTrackingState"
      :dataItem="dataItem"
      @hideModal="modalTrackingState = false"
    ></modal-tracking>
    <modal-tracking-status
      v-if="modalTrackingStatusState"
      :dataItem="dataItem"
      @hideModal="modalTrackingStatusState = false"
    ></modal-tracking-status>
    <modal-tracking-asign-clients-advisor
      v-if="modalTrackingClientsAssignedAdvisor"
      :dataItem="dataItem"
      @hideModal="modalTrackingClientsAssignedAdvisor = false"
    >
    </modal-tracking-asign-clients-advisor>
    <ModalEditLevel
      :dataItem="dataItem"
      v-if="modalEditAgentLevel"
      @hideModal="modalEditAgentLevel = false"
      @refresh="refresh"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import filters from "../data/filters.users.data";
import fields from "../data/fields.users.data";
import UsersService from "../../service/users.service";
import ModalToAssign from "./ModalToAssign.vue";
import ModalTracking from "./ModalTracking.vue";
import ModalClientsAssigned from "./ModalClientsAssigned.vue";
import ModalTrackingStatus from "./ModalTrackingStatus.vue";
import ModalTrackingAsignClientsAdvisor from "./ModalTrackingAsignClientsAdvisor.vue";
import { swalWarningIcon } from "@/icons/statusIcons";
import ModalEditLevel from "./ModalEditLevel.vue";
export default {
  components: {
    ModalToAssign,
    ModalTracking,
    ModalClientsAssigned,
    ModalTrackingStatus,
    ModalTrackingAsignClientsAdvisor,
    ModalEditLevel,
  },
  data() {
    return {
      recoveryMood: false,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      sortBy: "created_at",
      sortDesc: true,
      fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: filters,
      modaltoAssignState: false,
      modalTrackingState: false,
      modalTrackingStatusState: false,
      modalTrackingClientsAssignedAdvisor: false,
      modalClientsAssignedState: false,
      modalEditAgentLevel: false,
      dataItem: [],
      module_id: null,
    };
  },
  async created() {
    await this.loadModulesFilter();
    if (this.moduleC == 6) {
      this.fields.find((element) => element.key == "modules").visible = false;
    } else {
      this.fields.find((element) => element.key == "modules").visible = true;
    }
  },
  mounted() {
    this.init_filter();
  },

  methods: {
    init_filter() {
      if (this.moduleC == 6) {
        this.filter[3].visible = false;
        this.filter[4].visible = false;
      } else if (this.moduleC == 21) {
        this.filter[3].visible = true;
        this.filter[4].visible = true;
      }
    },

    showConfirmSwalForUsers(
      title = "Are you sure?",
      text = "You won't be able to revert this!",
      config = {}
    ) {
      return this.$swal({
        title,
        text,
        imageUrl: swalWarningIcon,
        imageWidth: 70,
        showCancelButton: true,
        buttonsStyling: false,
        showDenyButton: true,
        denyButtonText: "Later",
        confirmButtonText: "Assign Now",
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-outline-danger",
          denyButton: "btn btn-info mr-1",
        },
        ...config,
      });
    },
    openModalAssignClients(data) {
      this.dataItem = data;
      this.modalClientsAssignedState = true;
    },
    async activate(data) {
      try {
        const confirm = await this.showConfirmSwal(
          "Do you want to activate the advisor?"
        );
        if (confirm.value) {
          const params = {
            status: 1,
            user_id: data.user_id,
            user_session_id: this.currentUser.user_id,
          };
          this.addPreloader();
          const response = await UsersService.updateStatusOfAdvisor(params);
          if (response.status == 200) {
            this.removePreloader();
            data.status = 1;
          }
        }
      } catch (e) {
        console.error(e);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    async inactivate(data) {
      try {
        const params = {
          id: data.user_id,
        };
        const response = await UsersService.getCountClientsOfAdvisor(params);
        if (response[0].cantidad > 0) {
          const confirm = await this.showConfirmSwalForUsers(
            "There are assigned clients to this advisor. Please select another advisor to reassign them and inactivate the advisor.",
            "You can also do it later"
          );
          if (confirm.isConfirmed) {
            this.dataItem = data;
            this.modalClientsAssignedState = true;
          }
          if (confirm.isDenied) {
            await this.inactivateUser(data);
          }
        } else {
          const confirm = await this.showConfirmSwal(
            "Do you want to inactivate the advisor?"
          );
          if (confirm.value) {
            await this.inactivateUser(data);
          }
        }
      } catch (e) {
        console.error(e);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    async inactivateUser(data) {
      const params = {
        status: 0,
        user_id: data.user_id,
        user_session_id: this.currentUser.user_id,
      };
      this.addPreloader();
      const response = await UsersService.updateStatusOfAdvisor(params);
      if (response.status == 200) {
        this.removePreloader();
        data.status = 0;
      }
    },
    async loadModulesFilter() {
      try {
        const response = await UsersService.getAllModules();
        const firstOption = {
          value: "All",
          id: 0,
        };
        const newData = response.map((item) => ({
          value: item.name,
          id: item.id,
        }));
        newData.unshift(firstOption);
        this.filter[3].options = newData;
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    openModalTracking(data) {
      this.dataItem = data;
      this.modalTrackingState = true;
    },
    openModalTrackingStatus(data) {
      this.dataItem = data;
      this.modalTrackingStatusState = true;
    },
    openModalTrackingAsiggnClientsAdvisor(data) {
      this.dataItem = data;
      this.modalTrackingClientsAssignedAdvisor = true;
    },
    closeModal() {
      this.modaltoAssignState = false;
    },
    refresh() {
      this.$refs.refUsersTable1.refresh();
    },
    async myProvider() {
      try {
        const params = {
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          name_text: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          status: this.filter[2].model,
          // module: this.filter[3].model,
          module: this.moduleC,
          module_c: this.filter[3].model,
          role: this.filter[4].model,
          module_main_id: 6,
          module_children_id: 21,
        };
        let response = await UsersService.getAllUsers(params);
        if (response.status == 200) {
          this.startPage = response.data.from;
          this.paginate.currentPage = response.data.current_page;
          this.paginate.perPage = response.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = response.data.last_page;
          this.totalRows = response.data.total;
          this.toPage = response.data.to;
          return response.data.data;
        }
      } catch (error) {
        console.error(error);

        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    openModal(data) {
      this.dataItem = data;
      this.modaltoAssignState = true;
    },
    formatRoleDetails(item) {
      if (item.role_name != "Agent") return "";
      let levelName = item.type_senior;
      if (levelName == "begginer") {
        levelName = "trainer";
      }
      if (this.$route.meta.module_c == 20 && item.type_senior) {
        return `( ${item.languages} - ${String(levelName).toUpperCase()} )`;
      }
      return `( ${item.languages} )`;
    },
    openModalEditLevel(data) {
      this.dataItem = data;
      this.modalEditAgentLevel = true;
    },
    hasConnectionAgentRole(item) {
      let match = item.modules_roles.find(
        (role) => role.module_id == 20 && role.role_id == 15
      );
      return match != undefined;
    },
  },
  computed: {
    getModuleId() {
      return this.filter[3].model;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    moduleC() {
      return this.$route.meta.module_c;
    },
  },
  watch: {
    async getModuleId() {
      try {
        const params = {
          module_id: this.getModuleId,
        };
        this.filter[4].model = null;
        const response = await UsersService.getAllRolesForModule(params);
        const firstOption = {
          value: "All",
          id: 0,
        };
        const newData = response.map((item) => ({
          value: item.name,
          id: item.id,
        }));
        newData.unshift(firstOption);
        this.filter[4].options = newData;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped></style>

<template>
  <div>
    <b-modal
     ref="myModal"
      v-model="modalCenter"
      centered
      modal
      size="lg"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="TRACKING"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <template v-slot:modal-header>
        <ModalHeader
          :dataItem="dataItem"
          :title="'TRACKING ROLES'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <b-table
          slot="table"
          ref="refTrackingTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="visibleFields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(languages)="data">
            <div>
              {{ (data.item.role_id==15)?setLanguage(data.item):'' }}
            </div>
          </template>
          <template #cell(status)="data">
              <b-badge
                pill
                :variant="data.item.status == 'ADD' ? 'success' : 'danger'"
                class="text-capitalize w-100"
                >{{ data.item.status }}</b-badge
              >
          </template>
          <template #cell(created_at)="data">
            <div>
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
import UsersService from "../../service/users.service";
export default {
  components: {
    ModalHeader,
  },
  props: {
    dataItem: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "created_at",
          label: "Date",
          visible: true,
        },
        {
          key: "user_session",
          label: "User Session",
          visible: true,
        },
        {
          key: "module",
          label: "Module",
          visible: true,
        },
        {
          key: "role",
          label: "Role",
          visible: true,
        },
        {
          key: "languages",
          label: "Language",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          visible: true,
        },
      ],
    };
  },
  
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    setLanguage(data) {
        let language = ''
        if(data.languages==1){
            language='MONOLINGUAL'
        }else if(data.languages==2){
            language='BILINGUAL'
        } 
        return language
    },
    hideModal() {
      this.modalCenter = false;
      this.$refs['myModal'].hide();
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        const response = await UsersService.getAllTrackingForUser({
          user_id: this.dataItem.user_id,
        });
        return response.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <b-modal
    ref="modalEditLevel"
    v-model="modalcenter"
    centered
    modal
    size="lg"
    header-class="p-0"
    no-close-on-backdrop
    header-bg-variant="transparent border-bottom border-bottom-2"
    modal-class="modal-primary"
    title="TO ASSIGN"
    title-class="h2 text-white"
    @hidden="hideModal"
    hide-footer
    scrollable
  >
    <template v-slot:modal-header>
      <ModalHeader
        :dataItem="dataItem"
        :title="'EDIT AGENT LEVEL'"
        @close="hideModal()"
      />
    </template>
    <validation-observer ref="form">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-form-group label="Select Role">
            <validation-provider
              v-slot="{ errors }"
              name="role"
              rules="required"
              :key="keymodule2"
            >
              <b-select
                v-model="select_role"
                value-field="id"
                text-field="name"
                :options="optionsRoles"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
                @input="selectRole"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4" v-if="select_role == 15">
          <b-form-group label="Select Skill">
            <validation-provider
              v-slot="{ errors }"
              name="language"
              rules="required"
              :key="keymodule3"
            >
              <b-select
                v-model="select_language"
                :options="optionsLanguages"
                value-field="id"
                text-field="name"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
              >
              </b-select>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4" v-if="select_role == 15">
          <b-form-group label="Select Level">
            <validation-provider
              v-slot="{ errors }"
              name="level"
              rules="required"
              :key="keymodule3"
            >
              <b-select
                v-model="select_level"
                :options="optionsAgentLevel"
                value-field="id"
                text-field="name"
                class="w-full { 'is-border-danger': errors[0] }"
                :state="errors[0] ? false : null"
              >
              </b-select>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        class="w-100"
        style="display: block; margin: auto"
        variant="primary"
        @click="update"
        >Update</b-button
      >
    </validation-observer>
  </b-modal>
</template>

<script>
import ModalHeader from "./ModalHeader.vue";
import vSelect from "vue-select";
import UsersService from "../../service/users.service";
import { mapGetters } from "vuex";
export default {
  components: {
    vSelect,
    ModalHeader,
  },
  props: {
    dataItem: {
      type: Object,
    },
  },
  data() {
    return {
      keymodule1: 0,
      keymodule2: 0,
      keymodule3: 0,
      modalcenter: false,
      optionsModules: [],
      select_module: null,
      optionsRoles: [],
      select_role: null,
      select_language: null,
      select_level: null,
      flagHiddenLanguage: false,
      dataTable: [],
      optionsLanguages: [
        {
          id: 1,
          name: "MONOLINGUAL",
        },
        {
          id: 2,
          name: "BILINGUAL",
        },
      ],
      optionsAgentLevel: [
        {
          id: 1,
          name: "TRAINER",
        },
        {
          id: 2,
          name: "JUNIOR",
        },
        {
          id: 3,
          name: "SENIOR",
        },
        {
          id: 4,
          name: "MASTER",
        },
      ],
      currentRole: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    flag() {
      let module_id = this.select_module;
      let role_id = this.select_role;
      this.dataTable.forEach(function (item) {
        if (item.module_id == module_id && item.role_id == role_id) {
          return true;
        }
      });
      return false;
    },
  },
  created() {
    this.modalcenter = true;
    this.getCurrentRole();
    // this.getAllModules();
    this.selectModule();
  },
  methods: {
    // get roles
    async selectModule() {
      try {
        const params = {
          module_id: 20,
        };
        this.select_role = null;
        this.flagHiddenLanguage = false;
        this.optionsRoles = await UsersService.getAllRolesForModule(params);
      } catch (error) {
        console.error(error);
      }
    },
    selectRole() {
      if (this.select_role == 15) {
        this.flagHiddenLanguage = true;
        let flag = false;
        let languages = null;
        this.dataTable.forEach(function (item) {
          if (item.role_id == 15) {
            flag = true;
            languages = item.languages == "MONOLINGUAL" ? 1 : 2;
          }
        });
        if (flag) {
          this.select_language = languages;
        }
      } else {
        this.flagHiddenLanguage = false;
      }
    },
    // delete current role
    async deleteOldRole() {
      const params = {
        id: this.currentRole.id,
        user_session_id: this.currentUser.user_id,
        languages: this.select_language,
        parent_id: this.currentRole.parent_id,
        user_id: this.currentRole.user_id,
      };
      await UsersService.deleteRowUserModule(params);
    },
    // get actual role
    async getCurrentRole() {
      let params = {
        _user_id: this.dataItem.user_id,
      };
      let response = await UsersService.getAllModuleAndRoleForUser(params);
      this.currentRole = response.data.find(
        (role) => role.module == "CONNECTION"
      );
      if (this.currentRole == undefined)
        throw new Error("Doesn't have a connection role");

      // set role
      this.select_role = this.currentRole.role_id;
      // set skill
      switch (this.currentRole.languages) {
        case "MONOLINGUAL":
          this.select_language = 1;
          break;
        case "BILINGUAL":
          this.select_language = 2;
          break;
        default:
          throw new Error("Unknow language id");
      }
      // set level
      switch (this.dataItem.modules_roles[0].type_senior) {
        case "begginer":
          this.select_level = 1;
          break;
        case "Jr":
          this.select_level = 2;
          break;
        case "Senior":
          this.select_level = 3;
          break;
        case "Master":
          this.select_level = 4;
          break;
        default:
          throw new Error("Unknow level name");
      }
    },
    hideModal() {
      this.modalcenter = false;
      this.$refs['modalEditLevel'].hide();
      this.$emit("hideModal");
    },
    // update role
    async update() {
      let valid = await this.$refs.form.validate();
      if (!valid) return;
      await this.deleteOldRole();
      const params = {
        user_id: this.dataItem.user_id,
        module_id: 20, // only for connection mdfk
        role_id: this.select_role,
        user_session_id: this.currentUser.user_id,
      };
      // only settled for agents
      if (this.select_role == 15) {
        params.language = this.select_language;
        params.agent_level = this.select_level;
      }
      this.addPreloader();
      const response = await UsersService.saveModuleAndRole(params);
      if (response.status == 200) {
        this.removePreloader();
        this.$emit("refresh");
        this.select_module = null;
        this.select_role = null;
        this.select_language = null;
        this.flagHiddenLanguage = false;
        this.optionsRoles = [];
      } else {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
      this.hideModal();
    },
    async getAllModules() {
      this.optionsModules = await UsersService.getAllModules();
    },
  },
};
</script>
